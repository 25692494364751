
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const Videos = () => {

  return (
    <>
    {/* présente les créations autour du jeu, ici les vidéos */}
      <Navigation />
      <div className="content video-presentation">
        <h1>Vidéos sur Kintsugi Tracer</h1>
        <div className="videos-container">
          <div className="video-container">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/_51c6ktw7ys?si=5cV5J82OE0UgNA_R" title="Kintsugi presentation" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p>
              Dans le cadre de notre projet, nous avons réalisé une vidéo de présentation de notre jeu Kintsugi Tracer, elle a étais posté par notre professeur afin de promouvoir notre formation. Vous pouvez la visionner ci-dessous.
            </p>
          </div>

          <div className="video-container">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/vYocyQZU8dU?si=sWs776fT678IVTkK" title="Kintsugi pitch" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <p>
              J'ai aussi réalisé individuellement un pitch vidéo. Vous pouvez le visionner ci-dessous.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Videos;