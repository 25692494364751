import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

const Home = () => {
  return (

    <><Navigation />
{/* presente rapidement le jeu */}

      <div className="content">

        <h1>Bienvenue dans l'univers de Kintsugi Tracer</h1>
        <div className="content-home">
          <div className='intro'>
            <p >
              Plongez-vous dans une expérience de jeu immersive, mêlant habilement parkour, choix stratégiques et exploration visuelle captivante. Kintsugi Tracer, c'est bien plus qu'un simple runner en 3D ; c'est une aventure au cœur de la résilience.
            </p>
            <img className='img-kintsugi' src='KintsugiPresentation.png' alt="kintsugi" />
          </div>
          <div>
            <h4>Contexte de création :</h4>
            <p>
              Notre équipe a entrepris un voyage passionnant pour donner vie à Kintsugi Tracer. De l'idéation initiale à la recherche de références, du design 3D à la programmation complexe, chaque étape de création a été soigneusement orchestrée. Inspirés par la philosophie du kintsugi, l'art japonais de réparer les objets brisés avec de l'or, notre jeu explore le thème universel de la résilience à travers un parcours mental unique.
            </p>
          </div>
          <div>
            <h4>Le Chemin de la résilience :</h4>
            <p>
              Kintsugi Tracer n'est pas seulement un jeu, c'est une aventure symbolique où chaque fissure compte. Parcourez notre palais mental, surmontez les épreuves et atteignez une résilience complète. Chaque choix et chaque action contribuent à votre rémission.
            </p>

            <Link className='link-presentation' to={`/about`} >
              <h6>Si vous voulez en savoir plus sur le jeu rendez-vous sur la page de présentation
              </h6></Link>

          </div>
        </div>
      </div>
      <Footer />
    </>

  );

};
export default Home;