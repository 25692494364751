import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Form from '../components/Form';
import { useParams } from 'react-router-dom';
import React from 'react';

const Update = () => {
  const { projetId } = useParams();
  return (
// appel le composant form pour afficher un formulaire de modifications
    <><Navigation />
      <div className="content update-form">
        <Form  assetId={projetId}/>
      </div>
      <Footer />
    </>

  );

};
export default Update;
