import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

const Form = () => {
  const { projetId } = useParams();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    pathPng: '',
    pathFbx: '',
    pathTextures: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Chargez les données de l'asset lors du rendu initial
    fetch(`https://api.oriane-benatan.fr/get/${projetId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setFormData(data);
        console.log(data);
      })
      .catch(error => {
        console.error('Error during fetch operation:', error);
      });
  }, [projetId]);

  // fonction qui appel l'api pour mettre à jour l'asset avec une gestion des erreurs
  const handleFormSubmit = (e) => {
    e.preventDefault();

    fetch(`https://api.oriane-benatan.fr/update/${projetId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        console.log('PUT request successful');
        navigate(`/asset/${projetId}`);
      })
      .catch(error => {
        console.error('Error updating asset:', error);
      });
  };

  // a chaque changement dans le formulaire, on met à jour l'objet qui sera envoyé a l'api
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <h1>Modifier l'asset : {formData.name}</h1>
      <form onSubmit={handleFormSubmit}>
        <div>
          <div>
            <div className='label-input-group'>
              <label>Nom:</label>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
            </div>
            <div className='label-input-group'>
              <label>Description:</label>
              <textarea rows="6" cols="50" name="description" value={formData.description} onChange={handleInputChange} />
            </div>
          </div>
          <div>
            <div className='label-input-group'>
              <label>Chemin PNG:</label>
              <input type="text" name="pathPng" value={formData.pathPng} onChange={handleInputChange} />
            </div>

            <div className='label-input-group'>
              <label>Chemin FBX:</label>
              <input type="text" name="pathPng" value={formData.pathFbx} onChange={handleInputChange} />
            </div>

            <div className='label-input-group'>
              <label>Chemin Textures:</label>
              <input type="text" name="pathPng" value={formData.pathTextures} onChange={handleInputChange} />
              <span>Casse à respecter : 'chemin/vers/le/fichier','autre/chemin' </span>
            </div>
          </div>
        </div>
        <div>
          <Link to={`/asset/${projetId}`}><button>Annuler</button></Link>
          <button type="submit">Enregistrer les modifications</button>
        </div>

      </form>
    </>
  );
};

export default Form;
