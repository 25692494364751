import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';


const Update = () => {
  const { projetId } = useParams();
  const [asset, setAsset] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Charge les données de l'asset lors du rendu initial
    fetch(`https://api.oriane-benatan.fr/get/${projetId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAsset(data);
        console.log(data);
      })
      .catch(error => {
        console.error('Error during fetch operation:', error);
      });
  }, [projetId]);

  //fonction appelée lors du clic sur le bouton de suppression, appelle l'api pour supprimer l'asset et redirige vers la page des assets
  const handleDelete = () => {

    fetch(`https://api.oriane-benatan.fr/delete/${asset.id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        navigate('/assets');
      })
      .catch(error => {
        console.error('Error during delete operation:', error);
      });
  };
  if (!asset) {
    return (<><Navigation />
      <div>Loading...</div>
      <Footer /></>)
  }
  return (

    <><Navigation />


      <div className="content delete-form">
      <h3>Êtes vous sûr de vouloir supprimer l'asset {asset.name}?</h3>
      <div className='delete-form'>
          <button onClick={handleDelete}>Oui</button>
          <Link to={`/asset/${asset.id}`} ><button >Non, annuler</button></Link>
        </div>

      </div>
      <Footer />
    </>

  );

};
export default Update;
