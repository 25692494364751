import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import AssetPreview from '../components/AssetPreview';
import { Link } from 'react-router-dom';

const Assets = () => {
  const [assets, setAssets] = useState([]);
  const [selectedType, setSelectedType] = useState('');
//chargement de la fonction pour charger les assets
  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.oriane-benatan.fr/getall');
      setAssets(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'API', error);
      console.error('Erreur réseau:', error.message);
    }
  };
//appel de la fonction de chargement des assets
  useEffect(() => {
    fetchData();
  }, []);

  //met a jour le filtre
  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  return (
    <>
      <Navigation />

      <div className="content div-assets-container">
        <h2>Liste des assets 3D</h2>
        <ul className="radio-container">
          {["Personnages", "Plateformes", "Autres"].map((type) => (
            <li key={type}>
              <input
                type="radio"
                id={type}
                name="typeRadio"
                checked={type === selectedType}
                onChange={() => handleTypeChange(type)}
              />
              <label htmlFor={type}>{type}</label>
            </li>
          ))}
          <li>
            <button onClick={() => handleTypeChange('')}>Annuler la sélection</button>
          </li>
        </ul>
        <div className="assets-container">
          {/* liste les assets en fonction du filtre en créant des cards (AssetPreview) qui sont aussi des liens vers la page de l'asset en question */}
          {assets
            .filter((asset) => selectedType === '' || asset.type === selectedType)
            .map((asset) => (
              <Link className="div-asset-preview" to={`/asset/${asset.id}`} key={asset.id}>
                <AssetPreview name={asset.name} type={asset.type} pathPng={asset.pathPng} />
              </Link>
            ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Assets;
