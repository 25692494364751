import React from 'react';


const Footer = () => {
  return (
    // footer présent sur toutes les pages du site
    <footer className="footer">
      <p>&copy; 2024 Oriane Benatan. Tous droits réservés.</p>
    </footer>
  );
};

export default Footer;
