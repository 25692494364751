import * as THREE from 'three';
import { useEffect, useRef } from 'react';
import { FBXLoader } from 'three/addons/loaders/FBXLoader.js';
import { TextureLoader } from 'three/src/loaders/TextureLoader.js';

//tuto de https://dev.to/omher/how-to-start-using-react-and-threejs-in-a-few-minutes-2h6g avec amélioration à partir du code vu en cours
function Three({ fbxLink, textureLinks }) {
  const refContainer = useRef(null);

  useEffect(() => {
    // console.log('Three component rendered with links:', fbxLink, textureLinks);


    // === THREE.JS CODE START ===
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x464646);

    const camera = new THREE.PerspectiveCamera(50, 550 / 350, 0.005, 1000);
    camera.position.y = 10;
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(550, 350);
    refContainer.current && refContainer.current.appendChild(renderer.domElement);

    // Lights
    const hemiLight = new THREE.HemisphereLight(0x777777, 0xFFFFFF, 4);
    scene.add(hemiLight);
    hemiLight.position.z = -5;
    hemiLight.position.x = -10;
    hemiLight.position.y = 6;

    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.position.set(0, 1, 0);
    scene.add(dirLight);

    // Model
    const loader = new FBXLoader();
    const pivot = new THREE.Object3D();
    scene.add(pivot);

    const textureLoader = new TextureLoader();
    // const texture = textureLoader.load('/modeles/bigChara/golden_marble_baseColor.jpg');
    // const texture = textureLoader.load(textureLink);
    const textures = textureLinks.map(link => textureLoader.load(link));
    // console.log('Texture file to load:', textures);

    let objet = null;
    let first = true;

    // loader.load('/modeles/bigChara/bigChara.fbx',
    loader.load(fbxLink,
      (fbx) => {
        objet = fbx;
        // console.log('FBX loaded');
        objet.traverse((child) => {
          if (child.isMesh && child.material instanceof THREE.MeshPhongMaterial) {
            child.material.map = textures[child.materialIndex % textures.length];
            // console.log('Texture loaded');
          }
        });
      },
      undefined,
      (error) => { }
    );

    // Animation
    const animate = function () {
      requestAnimationFrame(animate);

      if (objet !== null) {
        if (first) {
          first = false;
          pivot.add(objet);

          const box = new THREE.Box3().setFromObject(objet);
          objet.updateMatrixWorld(true);
          box.applyMatrix4(objet.matrixWorld);

          const dx = box.max.x - box.min.x;
          const dy = box.max.y - box.min.y;
          const dz = box.max.z - box.min.z;

          const cx = box.min.x + dx / 2;
          const cy = box.min.y + dy / 2;
          const cz = box.min.z + dz / 2;

          // console.log(JSON.stringify(box));
          objet.position.set(-cx, -cy, -cz);
          camera.position.z = Math.sqrt(dx * dx + dy * dy + dz * dz);

          camera.position.y = 125;
          camera.rotation.x = -0.4;
          if (fbxLink == '/modeles/porte/porte.fbx' ) {
            camera.position.z = Math.sqrt(dx * dx + dy * dy + dz * dz)*1.2;
            camera.rotation.x = 0;
            camera.position.y = 10;
          }
        } else {
          pivot.rotation.y += 0.01;
        }
      }

      renderer.render(scene, camera);
    };

    animate();
  }, [fbxLink, textureLinks]);

  return (
    <div className='loader' ref={refContainer}></div>
  );
}

export default Three;
