import React, { useState } from 'react';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const AddAsset = () => {
  //préparation de l'envoi du formulaire
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "Personnages",
    pathPng: "",
    pathFbx: "",
    pathTextures: "",
  });

  //à chaque changement mettre à jour l'objet envoyé
  const handleChange = (evt) => {
    const { name, value } = evt.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //envoi du formulaire à l'api
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const { name, description, type, pathPng, pathFbx, pathTextures } = formData;
    const jsonData = JSON.stringify({
      name,
      description,
      type,
      pathPng,
      pathFbx,
      pathTextures,
    });

    try {
      const response = await fetch(`https://api.oriane-benatan.fr/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: jsonData,
      });

      if (response.ok) {
        // Réinitialise l'état après la soumission réussie du formulaire
        setFormData({
          name: '',
          description: '',
          type: 'Personnages',
          pathPng: '',
          pathFbx: '',
          pathTextures: '',
        });

        console.log("Formulaire soumis avec succès !");
      } else {
        console.error("Erreur lors de la soumission du formulaire.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la requête :", error);
    }
  };

  return (
    <>
    <Navigation />
    <div className="content">
      <h2>Ajouter un asset</h2>
      <form onSubmit={handleSubmit} className='add-asset' encType="multipart/form-data">
        <div className='input-label-group'>
          <label htmlFor="name">Nom :</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div className='input-label-group'>
          <label htmlFor="description">Description :</label>
          <input type="text" id="description" name="description" value={formData.description} onChange={handleChange} required />
        </div>
        <div className='input-label-group'>
          <label htmlFor="type">Type :</label>
          <select id="type" name="type" value={formData.type} onChange={handleChange}>
            <option value="Personnages">Personnages</option>
            <option value="Plateformes">Plateformes</option>
            <option value="Autres">Autres</option>
          </select>
        </div>
        <div className='input-label-group'>
          <label htmlFor="pathPng">Lien vers l'image :</label>
          <input type="text" id="pathPng" name="pathPng" value={formData.pathPng} onChange={handleChange} required />
          <p>Vous pouvez essayer avec un asset déjà présent dans les ressources :<br/>/modeles/addTest/tonneau.jpg</p>
        </div>
        <div className='input-label-group'>
          <label htmlFor="pathFbx">Lien vers FBX :</label>
          <input type="text" id="pathFbx" name="pathFbx" value={formData.pathFbx} onChange={handleChange} required />
          <p>Vous pouvez essayer avec un asset déjà présent dans les ressources :<br/>/modeles/addTest/tonneau.fbx</p>
        </div>
        <div className='input-label-group'>
          <label htmlFor="pathTextures">Lien vers texture :</label>
          <input type="text" id="pathTextures" name="pathTextures" value={formData.pathTextures} onChange={handleChange} required />
          <p>Vous pouvez essayer avec un asset déjà présent dans les ressources :<br/>'/modeles/addTest/UVTonneauBoisColor.jpg','/modeles/addTest/UVTonneauMetalColor.jpg'</p>
        </div>
        <button type="submit">Ajouter</button>
      </form>
    </div>
    <Footer />
  </>
  );
};

export default AddAsset;