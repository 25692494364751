import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={classNames('navbar', { 'navbar-open': isMenuOpen })}>
      <div className="navbar-toggle" onClick={toggleMenu}>
        ☰
      </div>
      <NavLink to="/home">Accueil</NavLink>
      <NavLink to="/about">Présentation</NavLink>
      <NavLink to="/video">Vidéos</NavLink>
      <NavLink to="/assets">Assets 3D</NavLink>
      <NavLink to="/addasset">Ajouter un Asset</NavLink>
    </nav>
  );
};

export default Navbar;