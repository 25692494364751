
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

const About = () => {

  return (
    //Explications du projet
    <><Navigation />
      <div className="content">
        <h1>Découvrez Kintsugi Tracer</h1>
        <div className="content-about">
          <h6 >
            Lorsque nous nous sommes lancés dans ce projet, nous avons dû relever le défi redoutable de développer un jeu complet à partir de zéro tout en restant fidèles au thème de la résilience. Les spécifications initiales nous ont chargés de créer un jeu à la première personne en basse poly, avec une architecture de code suivant les principes SOLID. Nous avons dû conceptualiser le jeu, créer le document de conception du jeu, puis plonger dans le développement réel.
          </h6>

          <div className='partie'>
            <h4>Caractéristiques clés du jeu :</h4>
            <div className='uls'>
              <ul>
                <li><h6>Gameplay Dynamique :</h6> Maîtrisez des mouvements fluides et des compétences uniques, dont le double saut et le dash, pour surmonter des obstacles inventifs.</li>
                <li><h6>IA Adaptative :</h6> Rencontrez ATIK, un guide humoristique qui réagit à vos actions, offrant une narration immersive.</li>
              </ul>

              <ul>
                <li><h6>Niveaux Réfléchis :</h6> Explorez des niveaux conçus avec soin, offrant des itinéraires alternatifs et des choix qui influencent votre parcours.</li>
                <li><h6>Design Visuel Épuré :</h6> Plongez dans un monde de marbre minimaliste, où chaque détail est pensé pour créer une esthétique cohérente.</li>
              </ul>
            </div>
          </div>

          <div className='partie'>
            <h4>Aspects techniques :</h4>
            <div className='uls'>
            <ul>
              <li><h6>Moteur de Jeu Unity :</h6>Kintsugi Tracer repose sur le puissant moteur de jeu Unity, offrant un environnement de développement robuste et flexible.</li>
              <li><h6>Vue à la Première Personne :</h6>L'expérience de jeu est intégralement conçue en vue à la première personne, offrant une immersion totale dans le monde mental du traceur.</li>
              <li><h6>Low-Poly Art et Design 3D :</h6> Le choix du style low-poly donne à Kintsugi Tracer une esthétique unique. Les décors en marbre, créés avec des textures soignées, apportent une cohérence visuelle remarquable.</li>
              <li><h6>Game Design Document (GDD) : </h6>Chaque aspect du jeu, des mécaniques de mouvement aux compétences spéciales, est minutieusement détaillé dans le Game Design Document. Cela a permis de maintenir une vision claire tout au long du processus de développement.</li>
              <li><h6>Narrative Design Document : </h6>L'histoire du jeu est élaborée dans le Narrative Design Document, garantissant une intégration harmonieuse des éléments narratifs tout en respectant le thème de la résilience.</li>
              <li><h6>Scripting en C# : </h6>Les mécanismes du jeu, y compris les contrôles (ZQSD, saut, slide, double saut, dash), ont été mis en œuvre avec des scripts C# soigneusement conçus. La gestion du joueur, l'IA d'ATIK, et d'autres aspects cruciaux du gameplay sont pris en charge par ces scripts.</li>
              <li><h6>Système de Compétences Dynamique : </h6>Les compétences, telles que le double saut et le dash, sont débloquées au fur et à mesure de la progression du joueur. Ce choix stratégique ajoute une dimension tactique au gameplay.</li>
            </ul>
            <ul>
            <li><h6>IA Adaptative (ATIK) : </h6> Le système d'IA d'ATIK fonctionne avec cinq états distincts, réagissant de manière humoristique et contextuelle aux actions du joueur dans le lobby et les niveaux.</li>
              <li><h6>Gestion du Temps et Chronomètre :</h6> Un chronomètre non oppressant mais stimulant ajoute une dimension compétitive au jeu, incitant les joueurs à améliorer leurs performances.</li>
              <li><h6>Bande-Son Dynamique : </h6>La musique originale, composée spécialement pour Kintsugi Tracer, s'adapte dynamiquement à l'intensité du jeu, créant une expérience audio enveloppante.</li>
              <li><h6>Niveaux Réfléchis et Adaptatifs :</h6> Les niveaux ont été soigneusement élaborés pour offrir des itinéraires variés, reflétant le style de jeu et les choix du joueur.</li>
              <li><h6>Système de Portes et Chemins Multiples :</h6>Des portes de sortie multiples dans chaque niveau offrent des choix significatifs, ajoutant de la profondeur à la progression du joueur.</li>
              <li><h6>Système de Lobby :</h6>L'IA d'ATIK patrouille dans le lobby, c'est l'endroit parfait pour s'entraîner</li>
              <li><h6>Développement itératif : </h6> Le processus de développement a été itératif, avec des phases de test et d'ajustement continu pour assurer une jouabilité fluide et une expérience sans accroc.</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;