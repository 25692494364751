import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Videos from './pages/Videos';
import Assets from './pages/Assets';
import Asset from './pages/Asset';
import AddAsset from './pages/AddAsset';
import Update from './pages/Update';
import Delete from './pages/Delete';


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/video" element={<Videos />} />
        <Route path="/assets" element={<Assets />} />
        <Route path="/addasset" element={<AddAsset />} />
        <Route path="/asset/:projetId" element={<Asset />} />
        <Route path="/asset/:projetId/update" element={<Update />} />
        <Route path="/asset/:projetId/delete" element={<Delete />} />
        {/*  404  */}
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;