import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Three from '../components/Three';
import { Link } from 'react-router-dom';

const Asset = () => {
  const { projetId } = useParams();
  const [asset, setAsset] = useState(null);


  useEffect(() => {
    // Charge les données de l'asset lors du rendu initial
    fetch(`https://api.oriane-benatan.fr/get/${projetId}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAsset(data);
        // console.log(data);
      })
      .catch(error => {
        console.error('Error during fetch operation:', error);
      });
  }, [projetId]);

  if (!asset) {
    return (<><Navigation />
      <div>Loading...</div>
      <Footer /></>)
  }

  return (
    <><Navigation />

<div className=' container-asset'>
        <div className='container-img'>
          <img src={'../' + asset.pathPng} alt="preview" />
        <Three fbxLink={asset.pathFbx} textureLinks={[asset.pathTextures]} />

        </div>
        <div className='container-txt'>
          <h1>{asset.name}</h1>
          <p>Description: <br /> {asset.description}</p>
          <p>Chemin PNG: {asset.pathPng}</p>
          <p>Chemin fbx: {asset.pathFbx}</p>
          <div className='container-btn'>
          <Link to={`update`} ><button >Modifier</button></Link>
          <Link to={`delete`} ><button >Supprimer</button></Link>

          </div>
        </div>

      </div><Footer /></>
  );
};

export default Asset;
