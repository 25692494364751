import React from 'react';


function AssetPreview(props) {
  return (
    <>
    {/* card de preview de l'asset avec un image, le nom et la catégorie */}
        <img src={`${process.env.PUBLIC_URL}${props.pathPng}`} alt="preview" />
        <div className="txt-asset">
          <h5> {props.name}</h5>
          <p>{props.type}</p>
          <p className='small'>Cliquez pour en savoir plus</p>
        </div>

    </>
  );
}

export default AssetPreview;